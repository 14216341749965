export const allTokens = [
  "Celestial Shikigami TOKEN",
  "Paper Shikigami TOKEN",
  "One-Tailed Fox TOKEN",
  "Destruction in White TOKEN",
  "Destruction in Black TOKEN",
  "Puppet TOKEN",
  "Ancient Artifact TOKEN",
  "Mystic Artifact TOKEN",
  "Serpent TOKEN",
  "Goblin King TOKEN",
  "Gargantuan Ghost TOKEN",
  "Crystalia Eve TOKEN",
  "Shield Guardian TOKEN",
  "Leonidas's Resolve TOKEN",
  "Magical Pawn TOKEN",
  "Megalorca TOKEN",
  "Hellflame Dragon TOKEN",
  "Draconic Weapon TOKEN",
  "Ephemeral Moon TOKEN",
  "Thorn Burst TOKEN",
  "Fairy Wisp TOKEN",
  "Fairy TOKEN",
  "Otohime's Bodyguard TOKEN",
  "Knight TOKEN",
  "Viking TOKEN",
  "Steelclad Knight TOKEN",
  "Strikeform Golem TOKEN",
  "Guardform Golem TOKEN",
  "Magic Sediment TOKEN",
  "Dragon TOKEN",
  "Mimi TOKEN",
  "Coco TOKEN",
  "Ghost TOKEN",
  "Forest Bat TOKEN",
  "Holy Falcon TOKEN",
  "Holy Tiger TOKEN",
];
